<template>
  <v-card>
    <v-card-title>契約科目-編集</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-form class="ma-6">
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label>契約科目名<span style="color: red">*</span></label>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              id="ci_name"
              v-model="$v.contractItemEditForm.ci_name.$model"
              outlined
              hide-details="auto"
              dense
              placeholder="契約科目名"
              :error-messages="ciNameErrors"
              :counter="100"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label>契約科目内容（日本語）<span style="color: red">*</span></label>
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            <v-textarea
              id="ci_content_ja"
              v-model="$v.contractItemEditForm.ci_content_ja.$model"
              :error-messages="contentJaErrors"
              :counter="400"
              hide-details="auto"
              rows="4"
              outlined
              dense
              placeholder="契約科目内容（日本語）"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label>契約科目内容（中国語）<span style="color: red">*</span></label>
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            <v-textarea
              id="ci_content_cn"
              v-model="$v.contractItemEditForm.ci_content_cn.$model"
              :error-messages="contentCnErrors"
              :counter="400"
              hide-details="auto"
              rows="4"
              outlined
              dense
              placeholder="契約科目内容（中国語）"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label>契約科目内容（英語）<span style="color: red">*</span></label>
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            <v-textarea
              id="ci_content_en"
              v-model="$v.contractItemEditForm.ci_content_en.$model"
              :error-messages="contentEnErrors"
              :counter="400"
              hide-details="auto"
              rows="4"
              outlined
              dense
              placeholder="契約科目内容（英語）"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col
            offset-md="5"
            cols="12"
          >
            <v-btn
              color="primary"
              :loading="submitStatus"
              @click="submit(contractItemEditForm)"
            >
              Submit
            </v-btn>
            <v-btn
              class="mx-2"
              outlined
              @click="cancelClick"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </v-card>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'

export default {
  data: () => ({
    submitStatus: false,
    contractItemEditForm: {
      ci_name: '',
      ci_content_ja: '',
      ci_content_cn: '',
      ci_content_en: '',
    },
    OriginalForm: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  validations: {
    contractItemEditForm: {
      ci_name: {
        required,
        maxLength: maxLength(100),
      },
      ci_content_ja: {
        required,
        maxLength: maxLength(400),
      },
      ci_content_cn: {
        required,
        maxLength: maxLength(400),
      },
      ci_content_en: {
        required,
        maxLength: maxLength(400),
      },
    },
  },
  computed: {
    ciNameErrors() {
      const errors = []
      if (!this.$v.contractItemEditForm.ci_name.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.contractItemEditForm.ci_name.maxLength && errors.push('長すぎます！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.contractItemEditForm.ci_name.required && errors.push('必須項目')

      return errors
    },
    contentJaErrors() {
      const errors = []
      if (!this.$v.contractItemEditForm.ci_content_ja.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.contractItemEditForm.ci_content_ja.maxLength && errors.push('長すぎます！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.contractItemEditForm.ci_content_ja.required && errors.push('必須項目')

      return errors
    },
    contentCnErrors() {
      const errors = []
      if (!this.$v.contractItemEditForm.ci_content_cn.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.contractItemEditForm.ci_content_cn.maxLength && errors.push('長すぎます！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.contractItemEditForm.ci_content_cn.required && errors.push('必須項目')

      return errors
    },
    contentEnErrors() {
      const errors = []
      if (!this.$v.contractItemEditForm.ci_content_en.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.contractItemEditForm.ci_content_en.maxLength && errors.push('長すぎます！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.contractItemEditForm.ci_content_en.required && errors.push('必須項目')

      return errors
    },
    ...mapState('contractStore', ['selectedContractItem']),
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadContractItem(this.$route.params.id)
        .then(() => {
          this.contractItemEditForm = this.$deepCopy(this.selectedContractItem)
          this.OriginalForm = JSON.stringify(this.contractItemEditForm)
        })
        .catch(error => {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
        .finally(() => {
          this.setOverlayStatus(false)
        })
    },
    jumpToListPage() {
      this.$router.push({
        path: '/contractItem-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    cancelClick() {
      if (this.OriginalForm !== JSON.stringify(this.contractItemEditForm)) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }

      this.jumpToListPage()
    },
    ...mapActions('contractStore', ['loadContractItem', 'editContractItem']),

    submit(contractItemEditForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.editContractItem(contractItemEditForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
            console.log('error', error.response.data)
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
      }
    },
  },
}
</script>
